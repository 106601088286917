import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";

import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import PopUpModal from "../Modal";

import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import DeactivateModal from "./DeactivateModal";
import ActivateShopsModal from "./ActivateModal";
import EditShop from "./EditShop";
import SelectForTable from "../TableViews/SelectForTable";
import ReasonsModal from "./ReasonsModal";
import ExtensionModal from "../Addons/ExtensionModal";
const DELAY_TIME = 100;

function ShopkeeperOnPayment({ province, city, shopType, salesman, search, status, setOrderBy, sizeOfPage, orderBy }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, toggle] = useState(false);
  const getPaginationLength = useApi(api.getOnPayNoOfPages);
  const [action, setAction] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const getshopkeepersOnPay = useApi(api.getShopKeepersOnPayment);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModeartorModal, setShowModeratorModal] = useState(false);
  const [Moderator, setModerator] = useState();
  const [Moderatorid, setModeratorid] = useState();
  const [subsType, setSubsType] = useState();

  const [modalStatusShow, setModalStatusShow] = useState(false);
  const [shopId, setShopId] = useState(null);
  const [deleteId, setDeleteId] = useState();
  const [isActive, setIsActive] = useState();
  const [showReasonsModal, setShowReasonsModal] = useState(false);
  const deactivateReasons = useApi(api.getDeactivateReasons);
  const [deactivateReason, setDeactivateReasons] = useState(false);
  const [extensionModal, setExtensionModal] = useState(false);
  const [shopData, setShopData] = useState(null);

  const closeModal = () => {
    setModalStatusShow(false);
  };

  const handleStatus = async (id, status) => {};

  const fetchpaginationCount = async () => {
    setIsLoading(true);

    try {
      const data = await getPaginationLength.request({
        size: sizeOfPage,
        province: province?.value,
        city: city?.value,
        salesman: salesman?.value,
        isActive: status.value,
        search: search,
      });
      setIsLoading(false);

      setCount(data.data.no_of_items);
      if (data.data.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data.data.no_of_pages);
        if (currentPage > data.data.no_of_pages) {
          setCurrentPage(data.data.no_of_pages);
        }
      }
    } catch {}
  };
  useEffect(() => {
    fetchpaginationCount();
    const pageTimeout = setTimeout(async () => {
      setIsLoading(true);
      const res = await getshopkeepersOnPay.request({
        province: province?.value,
        city: city?.value,
        salesman: salesman?.value,
        search: search,
        orderBy: orderBy,
        size: sizeOfPage,
        isActive: status.value,
        page: currentPage,
      });

      const data = res.data;
      setIsLoading(false);
      setItems(data);
      if (user.permissionArray.includes("Shopkeeper")) {
        setAction(true);
      } else {
        setAction(false);
      }
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [province, city, salesman, search, orderBy, currentPage, refresh, sizeOfPage, status]);

  const edit = (data) => {
    setShopId(data["id"]);
    setShowEditModal(true);
  };
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const LoginShops = useApi(api.LoginShops);
  const Login = async (username) => {
    try {
      const res = await LoginShops.request(username);
      const token = res.data.token.split(" ")[1];
      const urlString = `${process.env.REACT_APP_SELLER_URL}/?token=${token}`;
      window.open(urlString, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.log(error);
    }
  };
  const visit = (name) => {
    window.open(process.env.REACT_APP_MARKETPLACE_URL + "/store/" + name, "_blank", "noopener,noreferrer");
  };
  const changeSalesman = (data) => {
    setModeratorid(data["id"]);
    setModerator(data["salesman"]);
    setShowModeratorModal(true);
  };
  const getReasons = async (data) => {
    try {
      const dataa = await deactivateReasons.request(data["id"]);
      setDeactivateReasons(dataa.data[0].deactivation_reasons);
      setShowReasonsModal(true);
    } catch (error) {
      console.log(error);
    }
  };
  const trialExtension = async (data) => {
    setExtensionModal(true);
    setShopData(data);
    setShopId(data["id"]);
  };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] ? (
        <NoResult name="" />
      ) : (
        <>
          <CustomizeTableview
            data={items}
            columnNotShow={["id", "username", "is_subscription_cancelled", "subscription_status", "is_active", "current_plan_start_date", "banner_showing_start_date", "grace_period_ends_on", "subscription_type"]}
            edit={action ? edit : false}
            salesman={changeSalesman}
            reasons={status.value ? false : getReasons}
            // dateColumn={["created_at", "updated_at"]}
            dateColumn={["current_plan_end_date"]}
            sortColumn={["shop_name", "address", "email", "whatsapp", "salesman", "current_plan_end_date"]}
            visitButton={
              status.value === true && {
                label: "Visit",
                onClick: (name) => {
                  visit(name);
                },
                variant: "outline-primary",
                style: { padding: "8px", fontSize: "12px" },
                column: "id",
                isShow: (value) => {
                  return true;
                },
              }
            }
            loginButton={
              status.value === true && {
                label: "Login",
                onClick: (name) => {
                  Login(name);
                },
                variant: "primary",
                style: { padding: "8px", fontSize: "12px" },
                column: "id",
                isShow: (value) => {
                  return user?.user_type === "super-admin";
                },
              }
            }
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            ActiveDeactive={
              status.value === "awaiting"
                ? false
                : {
                    label: (data) => {
                      if (data["is_active"] === 1) {
                        return "Deactivate";
                      } else {
                        return "Activate";
                      }
                    },
                    onClick: (data) => {
                      if (data["is_active"] === 1) {
                        // handleDeactivate(data["id"]);
                        setShowDeactivateModal(true);

                        setShopId(data["id"]);
                      } else {
                        // handleActivate(data["id"]);
                        setShopId(data["id"]);
                        setSubsType(data["subscription_status"]);
                        setShowActivateModal(true);
                      }
                    },
                    variant: (data) => {
                      if (data["is_active"] === 1) {
                        return "outline-danger";
                      } else {
                        return "outline-primary";
                      }
                    },
                    style: { padding: "8px", fontSize: "12px" },
                    column: "is_active",
                  }
            }
            extension={status.value === true && trialExtension}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result </span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
        </>
      )}
      {/* Toggle Action Model */}

      <Modal show={modalStatusShow} onHide={closeModal} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Confirm {isActive ? "Deactivate" : "Activate"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            {/* <img src={triangle} alt="triangle" />
            <img
              src={dangerline}
              alt="dangerline"
              style={{ position: "absolute", right: "auto", top: "2.2rem" }}
            /> */}
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to {isActive ? "Deactivate" : "Activate"}?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button className="w-100 rounded-3" onClick={closeModal} variant="secondary">
              Cancel
            </Button>
            <Button className="w-100 rounded-3" onClick={() => handleStatus(deleteId, isActive)} variant={`${isActive ? "danger" : "primary"}`}>
              {isActive ? "Deactivate" : "Activate"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <PopUpModal title="Deactivate Shop" show={showDeactivateModal} onHide={() => setShowDeactivateModal(false)}>
        <DeactivateModal id={shopId} toggle={toggle} close={() => setShowDeactivateModal(false)} />
      </PopUpModal>
      <PopUpModal title="Activate Shop" show={showActivateModal} onHide={() => setShowActivateModal(false)}>
        <ActivateShopsModal
          id={shopId}
          subsType={subsType}
          shopType={shopType}
          close={() => setShowActivateModal(false)}
          toggle={toggle}
          // close={closeModalAdd}
        />
      </PopUpModal>
      <PopUpModal size="lg" title="Edit Shop" show={showEditModal} onHide={() => setShowEditModal(false)}>
        <EditShop
          shopId={shopId}
          close={() => setShowEditModal(false)}
          toggle={toggle}
          // close={closeModalAdd}
        />
      </PopUpModal>
      <PopUpModal size="sm" title="Change Moderator" show={showModeartorModal} onHide={() => setShowModeratorModal(false)}>
        <SelectForTable
          close={() => setShowModeratorModal(false)}
          id={Moderatorid}
          value={Moderator}
          toggle={toggle}
          // close={closeModalAdd}
        />
      </PopUpModal>
      <PopUpModal size="sm" title="Reasons" show={showReasonsModal} onHide={() => setShowReasonsModal(false)}>
        <ReasonsModal
          close={() => setShowReasonsModal(false)}
          toggle={toggle}
          reasons={deactivateReason}

          // close={closeModalAdd}
        />
      </PopUpModal>
      <PopUpModal title="Extend Plan" show={extensionModal} onHide={() => setExtensionModal(false)}>
        <ExtensionModal toggle={toggle} close={() => setExtensionModal(false)} data={shopData} />
      </PopUpModal>
    </>
  );
}

export default ShopkeeperOnPayment;
