import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import AddForm from "../../Components/AddForm";
import PopUpModal from "../../Components/Modal";

import style from "../../styles/AddInventory.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "../../Components/ReactSelect";
import axios from "axios";
import { useSelector } from "react-redux";
import SearchableSelect from "../../Components/SearchableSelect";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";
import useApi from "../../Hooks/useApi";
import * as postapi from "../../api/postapi";
import * as api from "../../api/getapi";

import { toast } from "react-toastify";
import { cleanup } from "@testing-library/react";
const EditPlans = ({ id, close, toggle, planData }) => {
  const { user } = useSelector((state) => state.auth);
  const user_type = user.user_type;

  const shop_id = user.shop_id;
  const user_id = user.user_id;

  const location = useLocation();
  const navigate = useNavigate();

  //form validation
  const [validatedVendor, setValidatedVendor] = useState(false);
  const [validatedBank, setValidatedBank] = useState(false);
  //vendors
  const [planName, setplanName] = useState("");
  const [planType, setPlanType] = useState(null);
  const [tempSave, setTempSave] = useState();
  const [yearlyTotal, setYearlyTotal] = useState("");
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [quarterlyTotal, setQuarterlyTotal] = useState("");
  const [selectedTheme, setSelectedTheme] = useState();
  const [subModulesOptions, setSubModulesOptions] = useState([]);
  const getSubModules = useApi(api.getSubModules);
  const [p_id, setP_id] = useState();

  const [theme, setTheme] = useState();
  const [modulesOptions, setModulesOptions] = useState([]);
  const [prices, setPrices] = useState([]);
  const [customPrices, setCustomPrices] = useState({
    secondaryUserTotal: 0,
    marketplaceThemeTotal: 0,
    smsTotal: 0,
    emailTotal: 0,
    locationLimitTotal: 0,
    modulesTotal: 0,
    themesTotal: 0,
    itemsTotal: 0,
    yearlyTotal: 0,
    monthlyTotal: 0,
    quarterlyTotal: 0,
  });
  const [customForm, setCustomForm] = useState({
    planName: "",
    planType: "",
    planAmount: "",
    itemsLimit: "",
    emailsLimit: "",
    smsLimit: "",
    locationLimit: "",
    yearlyTotal: 0,
    monthlyTotal: 0,
    quarterlyTotal: 0,
    primaryUser: "",
    secondaryUser: "",
    themes: [],
    modules: [],
    submodules: [],
  });

  const addplan = useApi(postapi.AddPlan);
  const [terms, setTerms] = useState("");
  const [error, setError] = useState("");
  const getsingleplan = useApi(api.getSinglePlan);
  const editplan = useApi(postapi.EditPLan);
  const FetchAllThemes = useApi(api.getAllThemes);
  const getParent = useApi(api.getParentModules);
  const getPricess = useApi(api.fetchAllConsumableItems);

  const handlePlanChange = (e) => {
    const { name, value } = e.target;

    setCustomForm({ ...customForm, [name]: value });
  };
  const handleSelectPlanChange = (e, name) => {
    if (name === "themes") {
      setCustomForm({ ...customForm, [name]: e });
    }
    setCustomForm({ ...customForm, [name]: e });
  };

  const fetchSubModulesData = async (id) => {
    setP_id(id);
    setSubModulesOptions(modulesOptions[id].subModules);
  };

  const handlePlanNameChange = (e) => {
    setplanName(e.target.value);
  };
  const getItemsPrices = async () => {
    getPricess
      .request()
      .then((res) => {
        setPrices(res.data.data.consumableItems);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const getThemes = async () => {
    await FetchAllThemes.request().then((res) => {
      setTheme(res?.data?.data);
    });
  };

  const ThemeOptions = theme?.map((cat) => ({ value: cat.id, label: cat.theme_name, price: cat.price }));
  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const data = await getsingleplan.request(id);
        setTempSave(data?.data);
        setCustomForm({
          ...customForm,
          planName: data?.data.plan_name,
          planType: { value: data?.data.plan_type, label: data?.data.plan_type },
          planAmount: data?.data.plan_amount,
          itemsLimit: data?.data.marketplace_items_limit,
          emailsLimit: data?.data.emails_limit,
          smsLimit: data?.data.sms_limit,
          locationLimit: data?.data.locations_limit,
          themes: data?.data.themes.map((cat) => ({ label: cat.theme_name, value: cat.id, price: cat.price })),
          secondaryUser: data?.data.secondary_users_limit,
        });
        setModulesOptions(data.data.modules);
        setQuarterlyTotal(Number(data.data.plan_amount) * 3 * 0.9);
        setYearlyTotal(Number(data.data.plan_amount) * 12 * 0.8);
        setMonthlyTotal(Number(data.data.plan_amount));
      } catch (error) {
        console.log(error);
      }
    };
    fetchPlan();
    getThemes();
    getItemsPrices();

    // fetchModulesData();
  }, []);

  const extractIds = (data) => {
    const ids = [];
    data.forEach((mainObj) => {
      if (mainObj.isChecked) {
        ids.push(mainObj.id);
      }
      mainObj.subModules.forEach((submodule) => {
        if (submodule.isChecked) {
          ids.push(submodule.id);
        }
      });
    });
    return ids;
  };
  const handleVendorSubmit = async (e) => {
    e.preventDefault();

    const finalObject = {
      plan: {
        plan_name: customForm.planName,
        plan_type: customForm.planType.value,
        plan_amount: customForm.planAmount,
        marketplace_items_limit: customForm.itemsLimit,
        emails_limit: customForm.emailsLimit,
        locations_limit: customForm.locationLimit,
        sms_limit: customForm.smsLimit,
        monthly_grand_total: Math.round(Number(customForm?.planAmount)),
        quarterly_grand_total: Math.round(quarterlyTotal),
        yearly_grand_total: Math.round(yearlyTotal),
        secondary_users_limit: customForm.secondaryUser,
      },
      themes: customForm.themes?.map((cat) => cat.value),
      modules: extractIds(modulesOptions),
    };

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    let valid = Object.values(finalObject.plan).some((item) => {
      if (item === "" || item.length === 0) {
        return true;
      } else {
        return false;
      }
    });

    if (!valid) {
      try {
        const data = await editplan.request(id, finalObject);
        close();

        if (data?.data.newPlan) {
          toast.success("Plan Edited Successfully", {
            position: "bottom-right",
            autoClose: 1500,
          });
          close();
          toggle((prev) => !prev);
        }
      } catch (error) {
        setError(error.response.data.error.message);
      }
    }
    setValidatedVendor(true);
  };
  const handleCheckboxChange = (e, index) => {
    e.stopPropagation();
    setP_id(index);
    const isChecked = e.target.checked;
    const values = modulesOptions;
    values.map((item, i) => {
      if (i === index) {
        return { ...item, isChecked: isChecked };
      }
      return item;
    });

    setModulesOptions((prev) =>
      prev.map((item, i) => {
        if (i === index) {
          // Update custom prices based on the checkbox stat

          // Update the checked state of the item
          return { ...item, isChecked: isChecked };
        }

        return item;
      })
    );

    if (!isChecked) {
      // Uncheck all submodules when the parent module is unchecked
      setModulesOptions((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              subModules: item.subModules.map((subItem) => ({
                ...subItem,
                checked: false,
              })),
            };
          }
          return item;
        });
      });
    }
  };
  const Themes = planData.map((theme) => {
    return { value: theme?.id, label: theme?.theme_name };
  });
  const handleSubmoduleCheckboxChange = (e, index, pr_id) => {
    if (modulesOptions[pr_id]?.isChecked ? modulesOptions[pr_id]?.isChecked : false) {
      setModulesOptions((prevModulesOptions) => {
        return prevModulesOptions.map((module, i) => {
          if (i === pr_id) {
            return {
              ...module,
              subModules: module.subModules.map((item, subIndex) => {
                if (subIndex === index) {
                  return { ...item, isChecked: e.target.checked };
                }
                return item;
              }),
            };
          }
          return module;
        });
      });
    } else {
      alert("Please select Parent Module first");
    }
  };

  const calculate = () => {
    let total = 0;
    modulesOptions.forEach((module) => {
      if (module.isChecked) {
        total = total + Number(module.price);
        module.subModules.forEach((subModule) => {
          if (subModule.isChecked) {
            total = total + Number(subModule.price);
          }
        });
      }
    });
    customForm.themes.forEach((theme) => {
      total = total + Number(theme.price);
    });
    const limitNameMapping = {
      itemsLimit: "marketplace_items_limit",
      smsLimit: "sms_limit",
      emailsLimit: "emails_limit",
      locationLimit: "locations_limit",
      secondaryUser: "secondary_users_limit",
      // Add other mappings if needed
    };

    Object.keys(limitNameMapping).map((val) => {
      const limitName = limitNameMapping[val];
      if (limitName) {
        prices.forEach((price) => {
          if (price.name === limitName) {
            const newAmount = price.per_unit_price * customForm[val];
            total = total + newAmount;
          }
        });
        // const newAmount = prices[limitName].per_unit_price * customForm[val];
        // total = total + newAmount;
      }
    });

    setCustomForm((prev) => ({ ...prev, planAmount: total }));
    if (customForm.planType?.value !== "trial") {
      setQuarterlyTotal(Number(total) * 3 * 0.9);
      setYearlyTotal(Number(total) * 12 * 0.8);
      setMonthlyTotal(Number(total));
    }
  };

  return (
    <>
      <Form noValidate validated={validatedVendor} onSubmit={handleVendorSubmit}>
        {error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>}

        <Row className="">
          <Col className="py-3" md={6}>
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>
                Plan Name<span className="text-danger">*</span>
              </label>
              <Form.Control
                value={customForm?.planName}
                className="p-2"
                onChange={(e) => {
                  // setplanName(e.target.value);
                  handlePlanChange(e);
                }}
                name="planName"
                type="text"
                placeholder="e.g. XYZ "
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter Plan Name.</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" md={6}>
            <Form.Group>
              <SearchableSelect
                placeholder="Select"
                label="Plan Type"
                value={customForm.planType}
                searchable={false}
                options={[
                  { value: "trial", label: "trial" },
                  { value: "fixed", label: "fixed " },
                  { value: "custom", label: "custom " },
                ]}
                handleChange={(e) => handleSelectPlanChange(e, "planType")}
              />
              <Form.Control value={planType} onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please select Plan Type.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-2" lg="6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="mb-1">
                Secondary User <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="number"
                onChange={(e) => {
                  handlePlanChange(e);
                }}
                placeholder="Enter secondary user"
                name="secondaryUser"
                value={customForm.secondaryUser}
              />
            </Form.Group>
          </Col>
          <Col lg="12 " className="mb-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <Form.Label> Associated Modules</Form.Label>

              <div className="d-flex gap-3 flex-wrap">
                {modulesOptions.map((item, index) => (
                  <div style={{ border: "1px solid aliceblue" }} className="d-flex flex-column  ">
                    <div style={{ maxHeight: "50px" }} onClick={() => fetchSubModulesData(index)} className=" mb-2 d-flex gap-3 p-2 bg-light align-items-center ">
                      <Form.Check
                        key={index}
                        className={""}
                        onChange={(e) => {
                          handleCheckboxChange(e, index);
                        }}
                        name={item?.name}
                        checked={item.isChecked}
                        type="checkbox"
                        id={`${index}`}
                      />
                      <label
                      //  htmlFor={`${index}`}
                      >
                        {item.name}
                      </label>
                    </div>
                    <Form.Group className="w-100" controlId="formBasicShopName">
                      <div className="d-grid ms-2 ">
                        {item?.subModules?.map((item, i) => (
                          <Form.Check
                            key={i}
                            className={""}
                            onChange={(e) => {
                              handleSubmoduleCheckboxChange(e, i, index);
                            }}
                            name={item.name}
                            checked={item.isChecked}
                            type="checkbox"
                            id={`${index}-${p_id}`}
                            label={`${item.name}`}
                          />
                        ))}
                      </div>
                    </Form.Group>
                  </div>
                ))}
              </div>
            </Form.Group>
          </Col>
          {/* <Col lg="12" className="mb-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <Form.Label> Associated Sub-Modules</Form.Label>

                  <div className="d-flex gap-3">
                    {modulesOptions[p_id]?.subModules?.map((item: any, index: any) => (
                      <Form.Check
                        key={index}
                        className={""}
                        onChange={(e) => {
                          handleSubmoduleCheckboxChange(e, index);
                    
                        }}
                        name={item.name}
                        checked={item.isChecked}
                        type="checkbox"
                        id={`${index}-${p_id}`}
                        label={`${item.name}`}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Col> */}
        </Row>

        <Form.Group className="w-100" controlId="formBasicShopName">
          <Form.Label> Associated Themes</Form.Label>
          <SearchableSelect
            searchable={false}
            multiselect={true}
            value={customForm.themes}
            options={ThemeOptions}
            name=""
            handleChange={(e) => handleSelectPlanChange(e, "themes")}
            // options={}
          />
        </Form.Group>

        <Row className="mt-2">
          <Col md={6}>
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>
                Items Limit <span className="text-danger">*</span>
              </label>
              <Form.Control
                value={customForm.itemsLimit}
                name="itemsLimit"
                onChange={(e) => {
                  // setItemsLimit(e.target.value.replace(/\D/g, ""));
                  handlePlanChange(e);
                }}
                type="number"
                placeholder="Rs.1000"
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>
                Emails Limit <span className="text-danger">*</span>
              </label>
              <Form.Control
                value={customForm.emailsLimit}
                name="emailsLimit"
                onChange={(e) => {
                  // setEmailsLimit(e.target.value.replace(/\D/g, ""));
                  handlePlanChange(e);
                }}
                type="number"
                placeholder="Rs.1000"
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>
                Locations Limit <span className="text-danger">*</span>
              </label>
              <Form.Control
                value={customForm.locationLimit}
                name="locationLimit"
                onChange={(e) => {
                  // setLocationLimit(e.target.value.replace(/\D/g, ""));
                  handlePlanChange(e);
                }}
                type="number"
                placeholder="eg.1000"
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-2" md={6}>
            <Form.Group className="mb-3" controlId="price">
              <label>
                Sms Limit <span className="text-danger">*</span>
              </label>
              <Form.Control
                value={customForm.smsLimit}
                name="smsLimit"
                onChange={(e) => {
                  handlePlanChange(e);

                  // setSmsLimit(e.target.value.replace(/\D/g, ""));
                }}
                type="number"
                placeholder="Rs.1000"
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="py-2 d-flex justify-content-center ">
            <Button onClick={calculate} className="w-75" variant="secondary">
              Calculate
            </Button>
          </Col>
          <Col className="pt-4" md={6}>
            <Form.Group className=" w-100" controlId="formBasicShopName">
              <Form.Label>
                Plan Amount<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                value={customForm.planAmount}
                name="planAmount"
                onChange={(e) => {
                  // setPlanAmount(e.target.value.replace(/\D/g, ""));
                  handlePlanChange(e);
                }}
                type="number"
                placeholder="Rs.1000"
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="pt-4" md={6}>
            <Form.Group className="mb-3" controlId="price">
              <Form.Label>
                Yearly Total<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control value={Math.round(yearlyTotal)} type="number" placeholder="Rs.1000" disabled />
              <Form.Control.Feedback type="invalid">Please Enter Amount of Purchase you make with this vendor.</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="" md={6}>
            <Form.Group className="mb-3" controlId="price">
              <Form.Label>
                Monthly Total <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control value={Math.round(monthlyTotal)} type="number" placeholder="Rs.1000" disabled />
              <Form.Control.Feedback type="invalid">Please Enter Amount of Purchase you make with this vendor.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="" md={6}>
            <Form.Group className="mb-3" controlId="price">
              <Form.Label>
                Quarterly Total <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control value={Math.round(quarterlyTotal)} type="number" placeholder="Rs.1000" disabled />
              <Form.Control.Feedback type="invalid">Please Enter Amount of Purchase you make with this vendor.</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="py-3">
          <Col>
            <Button type="submit" className="w-100">
              Save Plan
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default React.memo(EditPlans);
